.created-cell {
  /* height: 100%; */
  /* border: .1rem solid red; */
  margin: 0.1rem;
  background-color: #ebebeb;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10rem;
  /* cursor: pointer; */
  position: relative;
  flex-grow: 1;
  flex-basis: 0;
  padding: 1rem;
  overflow-x: auto;
  /* height: 50.0rem; */
}

.empty-created-cell {
  /* height: 100%; */
  /* border: .1rem solid red; */
  margin: 0.1rem;
  /* background-color: #ebebeb; */
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 10rem;
  /* cursor: pointer; */
  position: relative;
  flex-grow: 1;
  flex-basis: 0;
  height: 5rem;
  overflow-x: auto;
}

.scanner {
  top: 25%;
  height: 19.3rem;
  width: 25rem;
  background-color: var(--grey);
  font-size: 1.4rem;
}

.actual-scanner {
  border: none !important;
  /* color: var(--grey); */
}

#html5-qrcode-button-camera-start {
  /* display: none !important; */
}

#html5-qrcode-anchor-scan-type-change {
  pointer-events: none !important;
  display: none !important;
}

.f-btn-active {
  width: 90%;
  height: 4rem;
  font-size: 1.6rem;
  font-family: Poppins;
  border-radius: 1rem;
  cursor: pointer;
  border: none;
  box-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.466);
}

.optionListContainer {
  position: relative !important;
}

.attachment-disabled {
  height: 4rem !important;
  width: 100%;
  display: flex;
  font-size: 1.4rem;
  justify-content: center;
  align-items: center;
}

.attchment-name {
  width: 90%;
  display: flex;
  justify-content: center;
  background-color: white;
  height: 100%;
  align-items: center;
  opacity: 0.8;
}

.disabled-attachment-name {
  /* width: 90%; */
  display: flex;
  justify-content: center;
  background-color: var(--grey);
  height: 100%;
  align-items: center;
  opacity: 0.8;
  flex-grow: 1;
  border-top-right-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
  margin-right: 0.5rem;
}

.a-download-icon {
  cursor: pointer;
  background-color: dodgerblue;
  color: white;
  height: 100%;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 1.2rem;
  border-bottom-left-radius: 1.2rem;
  font-size: 2rem;
  margin-left: 0.5rem;
}

.remove-attachment {
  background-color: var(--red);
  color: var(--white);
  height: 100%;
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  border-top-right-radius: 1.2rem;
  border-bottom-right-radius: 1.2rem;
  margin-left: -1rem;
  z-index: 10;
  cursor: pointer;
}
