.audit-window {
  position: fixed;
  top: 5%;
  /* bottom: 5%; */
  left: 5%;
  right: 5%;
  background-color: white;
  /* border: .3rem solid black; */
  display: flex;
  flex-direction: column;
  z-index: 100;
  width: 90%;
}

.a-w-print-window {
  position: fixed;
  /* height: 63.1rem; */
  width: 59.5rem;
  background-color: white;
  /* border: .3rem solid black; */
  display: flex;
  flex-direction: column;
  z-index: 100;
  font-size: 1.2rem !important;
}

.a-w-print-window > * {
  margin: 0.5rem !important;
}

.a-w-print-window > div {
  font-size: 1rem !important;
}

.height-limit {
  height: 90vh;
}

.a-w-shadow {
  box-shadow: 0 0 0 9999.9rem rgba(0, 0, 0, 0.8);
}

.a-row {
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  margin: 0rem auto;
  font-size: 1.4rem;
}

.a-e-cell {
  flex-grow: 1;
  /* border: .1rem solid; */
  width: 30%;
  text-align: center;
}

.a-row-head {
  display: flex;
  /* margin-left: 2.0rem; */
  gap: 1rem;
  border: 0.2rem solid var(--main);
  width: 95%;
  justify-self: center;
  align-self: center;
  height: 3rem;
  padding: 0.2rem 0.5rem;
  color: var(--dark);
  font-size: 1.2rem;
  align-items: center;
  font-style: italic;
  margin-top: 2rem;
}

.a-row-head-state {
  background-color: var(--green);
  font-size: 1.6rem;
  padding: 0rem 1rem;
  font-style: normal;
}

.a-row-head-actor {
  display: flex;
  gap: 1rem;
  font-size: 1.3rem;
  font-style: italic;
}

.a-actor {
  background-color: var(--accent);
  color: var(--dark);
  padding: 0rem 1rem;
  font-size: 1.4rem;
}

.a-at {
  background-color: var(--main);
  color: white;
  padding: 0rem 1rem;
  font-size: 1.4rem;
}

.a-sub-head {
  background-color: var(--grey);
  color: var(--dark);
  height: 3rem;
  font-weight: bold;
}

.m-top {
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
}

.a-id {
  background-color: var(--black);
  color: var(--accent);
  padding: 0rem 1rem;
  font-style: italic;
}

.a-f-name {
  background-color: var(--main);
  color: var(--accent);
  padding: 0rem 1rem;
  font-style: italic;
}

.download-btn {
  background-color: var(--accent);
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  margin-right: 4rem;
  cursor: pointer;
  box-shadow: 0.05rem 0.05rem 0.5rem black;
  border-radius: 1rem;
}

.dark-bg {
  background-color: var(--main);
  color: var(--white);
  padding: 1rem 0.5rem;
}

.close-icon {
  margin-right: 1rem;
}

.g-row {
  display: flex;
  width: 90%;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0rem auto;
  font-size: 1.4rem;
  flex-direction: row;
}

.g-row-head {
  width: 33%;
  text-align: center;
}

.g-data-container {
  flex-grow: 1;
  margin-bottom: 2rem;
  background-color: var(--grey);
}

.g-data {
  display: flex;
  flex-direction: column;
  /* margin-left: 15%; */
  width: 100%;
}

.g-data-head {
  background-color: var(--dark);
  color: white;
  text-align: center;

  /* width: 100%; */
}

.g-data-log {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  font-style: italic;
  font-size: 1.4rem;
}

.n-g-a-row {
  display: flex;
  flex-direction: column;
}

.n-g-a-head {
  display: flex;
  width: 100%;
  background-color: var(--dark);
  color: white;
}
.n-g-a-log {
  display: flex;
  width: 100%;
}
.n-g-a-cell {
  display: flex;
  /* flex-grow: 1; */
  width: 100%;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid white;
}

.file-link {
  text-decoration: underline;
  cursor: pointer;
}

.file-link:hover {
  color: dodgerblue;
}
