.dashboard-app-container {
  display: flex;
  background-color: var(--grey);
  width: 100%;
  max-height: 92vh;
  min-height: 92vh;
  overflow-y: auto;
  flex-direction: column;
}

.app-name-dac {
  margin: 1rem 2rem 0rem 2rem;
  font-size: 2.4rem;
  color: var(--main);
}
.app-viz-container {
  margin-top: 1rem;
}

.app-lvl {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  width: calc(100%-4rem);
  margin: 1rem 2rem 0rem;
  justify-content: center;
  cursor: pointer;
  padding: 0.5rem;
  color: var(--dark);
  box-shadow: 0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.466);
}

.app-lvl-name {
  margin: 0.5rem;
  /* margin-bottom: 1.0rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.viz-lvl {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  gap: 0.5rem;
  padding: 0rem 4rem 0rem 4rem;
  margin-bottom: 0.5rem;
}

.viz-lvl-name {
  background-color: var(--grey);
  color: var(--main);
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.viz-n {
  flex-grow: 1;
  padding: 1rem 1.5rem;
  background-color: var(--main);
  color: var(--white);
}

.viz-i {
  padding: 0.9rem 1.4rem;
  cursor: pointer;
  background-color: var(--accent);
  border: 0.1rem solid var(--dark);
}

.close-flex {
  display: none !important;
}

.app-css {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.viz-count {
  background-color: var(--main);
  padding: 0rem 0.8rem;
  color: var(--white);
  border-radius: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.add-app-css {
  display: flex;
  gap: 1rem;
  align-items: center;
  background-color: var(--main);
  font-style: italic;
}

.add-app-lvl-name {
  /* margin: .5rem; */
  /* margin-bottom: 1.0rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--main);
  color: var(--white);
  padding: 0.5rem;
}

.add-app-inputs {
  display: flex;
  height: 100%;
}

.add-app-input {
  padding: 1.5rem 0rem;
  margin: 0rem 1rem;
}

.add-app-input > input {
  height: 3rem;
  font-weight: normal;
  font-size: 1.4rem;
  outline-color: var(--main);
  color: var(--main);
}

.add-app-input > select {
  height: 3.2rem;
  outline: none;
  font-weight: normal;
  font-size: 1.4rem;
  color: var(--main);
}

.add-viz-lvl {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  gap: 0.5rem;
  padding: 0rem 4rem 0rem 4rem;
  margin-bottom: 0.5rem;
  background-color: var(--light);
}

.add-app-btn {
  height: 50%;
  padding: 0rem 2rem;
  border-radius: 2rem;
  background-color: var(--main);
  border: none;
  color: var(--white);
  font-family: "Poppins";
  font-weight: bold;
  cursor: pointer;
}

.add-app-btn-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-a-icon > i {
  /* padding: .9rem 1.4rem; */
  cursor: pointer;
  /* border: .1rem solid var(--dark); */
  font-size: 4.2rem;
}

.f-a-icon {
  box-shadow: none;
  margin-right: 0.5rem;
  background: transparent;
  text-shadow: 0.3rem 0.3rem rgba(0, 0, 0, 0.129);
}

.viz-o-dtls {
  display: flex;
  flex-grow: 1;
  box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.466);
}
