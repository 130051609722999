.user-preview {
  position: fixed;
  top: 15%;
  bottom: 15%;
  left: 15%;
  right: 15%;
  background-color: white;
  border: 0.3rem solid black;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 0 9999.9rem rgba(0, 0, 0, 0.8);
  z-index: 100;
}

.role-creation-cell {
  /* height: 100%; */
  margin: 0.2rem;
  background-color: #bcc1c56f;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 10rem;
  cursor: pointer;
  position: relative;
  /* height: 5.0rem; */
}

.role-cell-control {
  width: 85%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
  gap: 3rem;
}

.role-cell-name {
  font-size: 1.6rem;
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}
