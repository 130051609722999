.creation-grid {
  /* height: 100%; */
  /* border: .1rem solid red; */
  margin: 0.2rem;
  background-color: #bcc1c56f;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 10rem;
  cursor: pointer;
  position: relative;
  height: 14rem;
  flex-direction: column;
}

.grid-head {
  height: 2rem;
  font-size: 1.8rem;
  width: 98%;
  display: flex;
  align-items: center;
}

.grid-controls {
  display: flex;
  width: 100%;
  height: 90%;
}
