.app-card-wrapper {
  display: flex;
  width: 20rem;
  height: 25%;
  /* background-color: var(--main); */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 2rem;
  transition: 1s;
}

.app-card {
  display: flex;
  width: 100%;
  height: 80%;
  /* background-color: var(--white) !important; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.458);
  border-radius: 2rem;
  cursor: pointer;
  transition: 1s;
}

.ac-icon {
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  text-shadow: 0rem 0.2rem 0.5rem #00000070;
  color: var(--dark);
}

.app-card:hover {
  transform: translate(0, -0.5em);
}

.app-card:hover > .ac-icon {
  font-size: 6rem;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.ac-name {
  /* height: 20%; */
  width: 18rem;
  font-weight: bold;
  color: var(--dark);
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  font-size: 1.4rem;
}

.settings-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 30%;
  left: 30%;
  width: 40%;
  background-color: var(--main);
  /* justify-content: center; */
  align-items: center;
  gap: 1rem;
  padding-bottom: 3%;
  box-shadow: 0 0 0 9999.9rem rgba(255, 255, 255, 0.8);
  z-index: 10;
}

.grow-20 {
  height: 10%;
}

.set-ctrl {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.set-label {
  width: 25%;
  color: white;
  font-size: 1.4rem;
}

.set-text {
  width: 60%;
}

.set-head {
  font-size: 2.5rem;
  color: white;
  margin-top: 5%;
}

.set-text > input {
  border-radius: 0rem;
  height: 80%;
  width: 100%;
  font-size: 1.3rem;
  font-style: italic;
  font-weight: normal;
}

.set-text > select {
  border-radius: 0rem;
  height: 80%;
  width: 101%;
  font-size: 1.4rem;
  font-weight: normal;
}

.set-close {
  color: white;
  position: fixed;
  top: 31%;
  left: 68%;
  cursor: pointer;
}

.inactive {
  pointer-events: none;
}

.set-icon {
  width: 61%;
  /* overflow-x: auto; */
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  background-color: white;
  /* height: 6.0rem; */
  padding-bottom: 0.5rem;
  /* gap: .5rem; */
  justify-content: flex-start;
}

.set-icon-option {
  background-color: var(--main);
  height: 5rem;
  width: 5rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  color: white;
  border-radius: 1rem;
  cursor: pointer;
}

.create-btn {
  padding: 0.5rem 2rem;
  background-color: var(--green);
  color: white;
  margin-top: 1rem;
  box-shadow: 0.05rem 0.05rem 0.5rem black;
  cursor: pointer;
  font-size: 1.4rem;
  border-radius: 1rem;
}

.cancel-btn {
  padding: 0.5rem 2rem;
  background-color: var(--red);
  color: white;
  margin-top: 1rem;
  cursor: pointer;
  box-shadow: 0.05rem 0.05rem 0.5rem black;
  font-size: 1.4rem;
  border-radius: 1rem;
}
