.cell-configure-window {
  position: fixed;
  width: 30%;
  top: 8%;
  left: 61%;
  bottom: 00%;
  background-color: var(--main);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 100;
  overflow-y: auto;
  display: flex;
}

.slide-in-move {
  animation: slide-in 1s 1 forwards;
  pointer-events: none;
  /* box-shadow: none !important; */
}

.slide-out-move {
  animation: slide-out 1s 1 forwards;
  /* pointer-events: none; */
  /* box-shadow: none !important; */
}

@keyframes slide-in {
  0% {
    transform: translateX(30%);
  }
  100% {
    transform: translateX(130%);
    /* opacity: 0; */
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(130%);
  }
  100% {
    transform: translateX(30%);
    /* opacity: 0; */
  }
}

.config-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 1rem;
  margin-top: 1rem;
}

.data-cols {
  display: flex;
  flex-direction: column;
  width: 97%;
  margin-top: 0.5rem;
}

.group-sel-cols {
  display: flex;
  background-color: var(--grey);
  font-size: 1.4rem;
  padding: 0.5rem;
  margin-top: 0.5rem;
}

.app-icon {
  cursor: pointer;
}

.label-n-text {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 97%;
  gap: 0.5rem;
  margin-bottom: 0.1rem;
}

.label {
  font-size: 1.4rem;
  /* width: 50%; */
  align-items: center;
  justify-content: center;
}

/* .text {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
} */

.text > input {
  height: 2rem;
  border-radius: 0rem;
  width: 98%;
  font-size: 1.4rem;
  text-align: left;
  background-color: var(--grey);
}

.text > select {
  height: 2rem;
  border-radius: 0rem;
  width: 99%;
  font-size: 1.4rem;
  outline-style: none;
  background-color: var(--grey);
}

.text > textarea {
  /* border-radius: .0rem; */
  width: 99%;
  font-size: 1.2rem;
  outline-style: none;
  height: 100%;
  box-sizing: border-box;
  font-style: italic;
  font-weight: normal;
  color: #0552cc;
  border-radius: 0rem;
  background-color: var(--grey);
}

.dtl {
  display: flex;
  width: 97%;
  flex-direction: column;
  align-items: center;
  background-color: var(--white);
  justify-content: center;
}

.dtl-head {
  display: flex;
  width: 97%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem solid black;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: bold;
}

.dtls-col {
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
  justify-content: center;
  margin-top: 0.5rem;
  flex-direction: column;
  align-items: center;
}

/* .viz-conf-header {
  display: flex;
  color: var(--white);
  justify-content: space-between;
  width: 90%;
  align-items: center;
} */

.measure-col {
  display: flex;
  margin-right: 1rem;
  font-size: 1rem;
  color: var(--main);
  margin: 0.2rem;
  font-style: italic;
  width: 100%;
  gap: 0.2rem;
}

.measure-sel-cols {
  display: flex;
  flex-direction: column;
  background-color: var(--grey);
  font-size: 1.4rem;
  padding: 0.5rem;
  margin-top: 0.5rem;
  width: 97%;
}

.measure-col-icon {
  font-size: 1.4rem;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
  /* width: 100%; */
  color: var(--main);
}

.measure-col-name {
  background-color: var(--white);
  border-radius: 1rem;
  padding: 0rem 0.5rem;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
}

.measure-options {
  flex-grow: 1;
}

.measure-options > select {
  height: 100%;
  font-size: 1.4rem;
  padding: 0;
  outline: none;
  width: 100%;
  color: var(--main);
  font-weight: normal;
  border: none;
}

.filter-options {
  flex-grow: 1;
  /* width: 100%; */
}

.filter-ops > select {
  height: 100%;
  font-size: 1.4rem;
  padding: 0 0.3rem;
  outline: none;
  color: var(--main);
  font-weight: normal;
  border: none;
}

.filter-options > input {
  height: 100%;
  font-size: 1.4rem;
  padding: 0 0 0 0.3rem;
  outline: none;
  width: 97%;
  color: var(--main);
  font-weight: normal;
  border: none;
  text-align: left;
  font-style: italic;
}

.filter-col-name {
  background-color: var(--white);
  border-radius: 1rem;
  padding: 0rem 0.5rem;
  /* width: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-col-name > select {
  height: 100%;
  font-size: 1.4rem;
  padding: 0 0.3rem;
  outline: none;
  color: var(--main);
  font-weight: normal;
  border: none;
  font-style: italic;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}

.filter-options > input::placeholder {
  font-family: "Poppins";
  font-size: 1.3rem;
  color: var(--dark-grey);
  font-weight: normal;
  text-align: center;
}

.add-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0rem 0.2rem;
  font-size: 1.3rem;
}

.logic-op > select {
  height: 100%;
  font-size: 1.3rem;
  width: 100%;
  padding: 0rem 0.5rem;
  outline: none;
  color: var(--main);
  font-weight: normal;
  border: none;
  font-style: italic;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}

.logic-op {
  width: 12%;
}

.color {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.color > input {
  height: 2.5rem;
  border: none;
  border-radius: 0rem;
  width: 98%;
  /* font-size: 1.4rem; */
  text-align: left;
  background-color: var(--grey);
}

.selected-accesses {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 0.5rem 0rem;
  font-size: 1.4rem;
}

.selected-access {
  padding: 0.5rem 1rem;
  background-color: var(--main);
  color: white;
  cursor: pointer;
  font-size: 1.4rem;
}

.dtl-col-row {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  font-size: 1.4rem;
}

.dtl-col-row-head {
  display: flex;
  width: 98%;
  justify-content: space-evenly;
  font-size: 1.4rem;
  background-color: var(--main);
  color: white;
}

.dtl-col-col {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
}

.dtl-col-col > input[type="checkbox"] {
  height: 100%;
}

.conf-icons {
  display: flex;
  gap: 2rem;
  font-size: 1.8rem;
}

.conf-icons > .fa-trash {
  color: var(--red);
  cursor: pointer;
}

.conf-icons > .fa-floppy-disk {
  color: var(--green);
  cursor: pointer;
}
