.u-d-container {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 0rem;
  /* margin-left: -3.0rem; */
}

.u-menu {
  display: flex;
  flex-direction: column;
  /* position: fixed; */
  width: 20%;
  height: 100%;
  background-color: var(--grey);
  /* left: -19.5%; */
  /* left: 0%; */
  transition: 1s;
  /* align-items: center; */
}

/* .u-menu:hover {
  left: 0%;
} */

.u-menu-head {
  background-color: var(--accent);
  border: 0.1rem solid var(--main);
  width: 100%;
  text-align: center;
  padding: 0.5rem 0rem;
  cursor: pointer;
  font-size: 1.6rem;
  /* margin-bottom: .5rem; */
  /* box-shadow: .5rem .5rem 1.0rem rgba(0, 0, 0, 0.466); */
  position: relative;
}

.u-menu-part {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding-bottom: 1rem;
  background-color: var(--main);
  overflow-y: auto;
  font-size: 1.5rem;
}

.u-menu-i-head {
  padding-left: 2rem;
  background-color: var(--main);
  color: var(--white);
}

.u-menu-i {
  background-color: var(--grey);
  color: var(--main);
  border-bottom: 0.1rem solid var(--main);
  padding: 0.2rem 0rem 0.2rem 4rem;
  cursor: pointer;
}

.p-not {
  background-color: yellow;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
  position: absolute;
  top: 35%;
  right: 5%;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
