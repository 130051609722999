.add-data-col {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  border-radius: 2rem;
  background-color: var(--main);
  color: var(--white);
  /* width: 2.0rem; */
  padding: 0.3rem;
  position: relative;
  /* width: 100%; */
}

.add-icon {
  cursor: pointer;
  font-size: 1.4rem;
}
.col-options {
  position: absolute;
  /* height: 10.0rem; */
  /* width: 20.0rem; */
  background-color: var(--main);
  left: 0%;
  top: 0%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 10;
  flex-direction: column;
  box-shadow: 0.3rem 0.3rem 1rem black;
  border-radius: 1rem;
  padding: 0.5rem;
}

.c-o-heading {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: flex-end;
}

.c-o-head {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.c-o-label {
  font-size: 1.4rem;
  width: 80%;
}

.available-cols {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.col {
  display: flex;
  margin-right: 1rem;
  font-size: 1.3rem;
  background-color: var(--white);
  border-radius: 1rem;
  color: var(--main);
  padding: 0rem 0rem 0rem 0.5rem;
  margin: 0.2rem;
  font-style: italic;
  /* width: 100%; */
}

.col-icon {
  margin-left: 0.5rem;
  font-size: 1.4rem;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  /* width: 100%; */
  background-color: var(--dark-grey);
  color: var(--white);
}
