.navbar {
  display: flex;
  align-items: center;
  background-color: var(--dark);
  box-shadow: 0.5rem 0.5rem 0.7rem;
  height: 8vh;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5rem;
  margin-top: 0rem;
}

.appname {
  font-family: "Reem Kufi";
  color: var(--white);
  font-weight: bold;
  font-size: 4rem;
  margin-right: 2rem;
}

/* .delogo {
  padding: .5rem .5rem .5rem .5rem;
  content: url("./../../public/Group\ 1.svg");
  object-fit: fill;
  height: 4.5rem;
  margin-right: 1.0rem;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
} */

.applogo {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  content: url("./../../public/fi.png");
  object-fit: fill;
  height: 6rem;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.options {
  flex: 1.8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.8rem;
  vertical-align: middle;
  font-family: "Poppins";
}

.options > div {
  /* margin-right: 10.0rem; */
  color: var(--white);
  font-size: 1.6rem;
}

.options > div > a {
  margin-right: 10rem;
  color: var(--white);
}

.icon-account {
  font-size: 4.30703rem;
  color: var(--white);
}

.account {
  width: 4.7rem;
  height: 4.7rem;
  border-radius: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3rem;
  border-color: var(--white);
  border-style: solid;
  transition: filter 300ms;
}

.account:hover {
  filter: brightness(1.2);
}

.account > div {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 2rem;
}

.dropdown {
  background-color: var(--white);
  position: absolute;
  top: 5%;
  right: 1%;
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: 0rem 0.8rem 1.6rem 0rem rgba(0, 0, 0, 0.2);
  display: none;
  list-style: none;
  text-decoration: none;
  padding: 1rem 0rem 1rem 0rem;
  z-index: 1000;
  width: 15rem;
}

li {
  text-align: center;
}

.dropdown-item {
  padding: 0.5rem;
  text-decoration: none;
  color: black;
  font-family: "Poppins";
  font-size: 1.3rem;
}

a {
  text-decoration: none;
}

.dropdown:hover .dropdown .account:hover {
  display: block;
}

.account:hover + .dropdown {
  display: block;
}

.dropdown:hover {
  display: block;
}

.dropdown-item:hover .dropdown {
  display: block;
}

.dropdown-item:hover {
  background-color: #cccccc;
}

.hidden {
  display: none;
}

.pi-dd {
  margin-left: 5rem;
}

.user {
  background-color: var(--main);
  color: #ffffff;
}

.delogo {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  content: url("./../../public/delogo1.png");
  object-fit: fill;
  height: 4.5rem;
  margin-right: 1rem;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.client-logo {
  content: url("../../public/client-logo.png");
  background-color: white;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  object-fit: fill;
  height: 4.5rem;
  margin-right: 1rem;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}
