.f-head {
  display: flex;
  width: 95%;
  height: 5%;
  background-color: var(--white);
  justify-content: space-evenly;
}

.f-head-in {
  padding: 0.5rem 1rem;
  background-color: var(--accent);
  color: var(--dark);
  box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.542);
  cursor: pointer;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.f-head-in-selected {
  background-color: var(--main);
  color: var(--white);
}

.f-head-set {
  padding: 0.5rem 4rem;
  background-color: var(--main);
  color: var(--white);
  height: 10%;
  display: flex;
  align-items: center;
}

.f-setting-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  /* justify-content: center; */
  align-items: center;
  margin-top: 1rem;
}

.f-set-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 90%;
  align-items: center;
  justify-content: center;
  margin-top: 2.5%;
}

.set-ctrls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 80%;
}
