.new-esign-input {
  width: 100%;
  margin: 1rem 1rem 0rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dark);
}

.new-esign-label {
  width: 50%;
  font-family: "Poppins";
  font-size: 1.6rem;
  font-weight: bold;
  text-align: right;
  margin-right: 2rem;
  display: flex;
  justify-content: flex-start;
}

.create-job-header {
  width: 100%;
}

.flex-row-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 2rem 0rem 2rem;
}

.close-flex {
  display: none !important;
}

.margin-btm {
  margin-bottom: 3rem;
}

.new-job-icon {
  font-size: 2.8rem;
  color: var(--dark);
  margin-right: 1rem;
}
.new-job-head {
  text-align: center;
  font-size: 1.6rem;
  color: var(--dark);
  font-family: "Poppins";
  font-weight: bold;
}

.new-job-input {
  width: 100%;
  margin: 3rem 2rem 1rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dark);
}

.new-job-label {
  width: 50%;
  font-family: "Poppins";
  font-size: 1.6rem;
  font-weight: bold;
  margin-left: 2rem;
  text-align: right;
  margin-right: 2rem;
}

.new-job-ta {
  width: 100%;
  text-align: left;
}
.btn-save {
  background-color: #00cc83;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  font-family: "Poppins";
  border-radius: 1rem;
  margin: 2rem 1rem 2rem 1rem;
  cursor: pointer;
  color: white;
  font-size: 1.4rem;
  box-shadow: 0.05rem 0.05rem 0.5rem black;
}

.btn-cancel {
  background-color: #ff5f5f;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  font-family: "Poppins";
  border-radius: 1rem;
  margin: 2rem 1rem 2rem 1rem;
  cursor: pointer;
  color: white;
  font-size: 1.4rem;
  box-shadow: 0.05rem 0.05rem 0.5rem black;
}
.esign-modal {
  position: fixed;
  top: 30%;
  left: 40%;
  /* width: 20%; */
  background-color: var(--white);
  border-style: solid;
  border-color: var(--main);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
  border-radius: 2rem;
  box-shadow: 0 0 0 9999.9rem rgba(0, 0, 0, 0.8);
  cursor: pointer;
  padding: 2rem;
}
