.i-a-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green);
  padding: 0.5rem 2rem;
  color: var(--white);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.466);
  font-size: 1.6rem;
  cursor: pointer;
  border-radius: 2rem;
  margin: 0.5rem 2rem;
}

.r-menu {
  display: flex;
  width: 100%;
}

.r-name {
  width: 90%;
  display: flex;
  justify-content: center;
}
