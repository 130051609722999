.create-container {
  height: 92vh;
  display: flex;
  width: 100%;
  background-color: var(--white);
}

.components-container {
  background-color: var(--main);
  width: 20%;
  display: flex;
  flex-direction: column;
  color: var(--white);
  align-items: center;
  gap: 1rem;
  font-size: 1.4rem;
  /* margin-top: 1.0rem; */
  /* border: .1rem solid black; */
}

.creation-container {
  content: "";
  width: 80%;
  border-width: 0.1rem;
  border-style: dashed;
  margin: 2rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.app-name-container {
  display: flex;
  width: 97%;
  align-items: center;
  justify-content: center;
  /* margin-top: 2.0rem; */
}

.app-name-container > select {
  height: 3rem;
  width: 100%;
  border-radius: 0rem;
  color: var(--white);
  background-color: black;
}

.layout-container {
  width: 97%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 50%;
  /* overflow-x: auto; */
  /* margin: .0rem 1.0rem; */
}

.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--white);
  align-items: center;
  gap: 1rem;
  overflow-y: auto;
  max-height: 100%;
}

.layout-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: #bcc1c5;
}

.layout-cell {
  font-size: 2.5rem;
}

.layout-add {
  font-size: 1.8rem;
  cursor: pointer;
  color: black;
}

.add-row {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  width: 30%;
  background-color: black;
  height: 2rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.creation-row {
  display: flex;
  border: 0.2rem dashed var(--main);
  /* width: 100%; */
  margin: 0.2rem;
}

.viz-container {
  width: 97%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0rem 1rem;
  flex-direction: column;
  background-color: var(--white);
  margin-bottom: 2rem;
}

.viz-options {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: black;
}

.viz-option-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.component-header {
  color: black;
  font-weight: bold;
  padding: 0.5rem 0rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  /* margin: .0rem .5rem; */
  align-items: center;
  width: 97%;
  border-bottom: 0.1rem solid black;
}

.viz-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 1rem;
}

.viz-name {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* background-color: grey; */
}

.big-font {
  font-size: 2.2rem !important;
  font-weight: bold;
  font-family: "Poppins";
  /* color: white; */
}

.form-viz-name {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.viz-frequency {
  width: 8%;
}
.viz-filter {
  width: 8%;
}
.viz-name > input {
  background-color: #bcc1c56f;
  color: #0055c2;
  width: 100%;
}

.viz-name > input::placeholder {
  color: gray;
}

.form-viz-name > input {
  background-color: #bcc1c56f;
  color: #0055c2;
  width: 100%;
}

.form-viz-name > input::placeholder {
  color: gray;
}

.viz-frequency > select {
  background-color: #bcc1c56f;
  color: #0055c2;
  width: 100%;
  font-size: 1.2rem;
  outline-style: none;
  height: 100%;
}

.viz-filter > input {
  background-color: #bcc1c56f;
  color: #0055c2;
  width: 100%;
  padding: 0rem 0.5rem;
  height: 100%;
}

.viz-filter > input::placeholder {
  color: gray;
}

.preview-btn {
  background-color: #979da6;
  border-radius: 1rem;
  height: 3rem;
  border-style: none;
  box-shadow: 0.05rem 0.05rem 0.5rem black;
  cursor: pointer;
  margin-right: 1rem;
  padding: 0rem 1rem;
  color: var(--white);
  font-family: Poppins;
}
.save-btn {
  background-color: var(--green);
  color: var(--white);
  font-family: Poppins;
  border-radius: 1rem;
  height: 3rem;
  border-style: none;
  box-shadow: 0.05rem 0.05rem 0.5rem black;
  cursor: pointer;
  margin-right: 1rem;
  padding: 0rem 1rem;
}
.publish-btn {
  background-color: #04bfad;
  border-radius: 1rem;
  height: 3rem;
  border-style: none;
  box-shadow: 0.5rem 0.5rem 0.7rem rgba(0, 0, 0, 0.469);
  cursor: pointer;
  margin-right: 1rem;
  padding: 0rem 1rem;
  color: var(--white);
  font-family: Poppins;
}

.form-workflow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  /* background-color: var(--white); */
  height: 4rem;
}

.fw-option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  cursor: pointer;
  /* height: 5.0rem; */
}

.fw-option-selected {
  background-color: var(--accent);
  color: var(--dark);
}

.workflow-creation-container {
  content: "";
  width: 80%;
  margin: 2rem;
  display: flex;
  flex-direction: column;
  height: 97%;
}

.wf-creation-container {
  content: "";
  border-width: 0.1rem;
  border-style: dashed;
  margin: 2rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.success-node {
  fill: var(--green);
  font-family: "Poppins";
  outline: 0rem outset #2bf3ad;
  /* outline-offset: .0rem; */
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-node:hover {
  fill: #2bf3ad;
}

.success-node text {
  fill: white;
  font-size: 1rem;
  font-family: "Poppins";
  text-align: center;
}

.start-node {
  fill: #0055c2;
  font-family: "Poppins";
  outline: 0rem outset #0055c2;
  /* outline-offset: .0rem; */
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.start-node:hover {
  fill: #0055c2;
}

.start-node text {
  fill: white;
  font-size: 1rem;
  font-family: "Poppins";
  text-align: center;
}

.end-node {
  fill: #fea260;
  font-family: "Poppins";
  outline: 0rem outset #fea260;
  /* outline-offset: .0rem; */
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.end-node:hover {
  fill: #fea260;
}

.end-node text {
  fill: white;
  font-size: 1rem;
  font-family: "Poppins";
  text-align: center;
}

path {
  stroke: #333;
  fill: #333;
  stroke-width: 1.5rem;
}

.layout-cells {
  display: flex;
  align-items: center;
  border: 0.1rem dashed var(--white);
  gap: 0.4rem;
  padding: 0rem 0.4rem;
}

.selected-layout-row {
  display: flex;
  align-items: center;
  background-color: var(--main);
  border: 0.2rem solid var(--main);
  gap: 0.4rem;
  padding: 0rem 0.4rem;
}

.ai-create-btn {
  display: flex;
  height: 90%;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: #0055c2;
  padding: 0rem 1rem;
  color: white;
  border-radius: 1rem;
  cursor: pointer;
  font-size: 1.4rem;
  box-shadow: 0.05rem 0.05rem 0.5rem black;
}

.ai-create-btn > i {
  font-size: 2.5rem;
}
