.created-grid-cell {
  /* height: 100%; */
  /* border: .1rem solid red; */
  margin: 0.1rem;
  background-color: #ebebeb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10rem;
  /* cursor: pointer; */
  position: relative;
  flex-grow: 1;
  flex-basis: 0;
  padding: 1rem;
  /* overflow-x: auto; */
  overflow-y: auto;
  min-height: 14rem;
  max-height: 40rem;
}

.add-new-gr {
  font-size: 1.4rem;
  display: flex;
  /* width: 10%; */
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 0.2rem 1rem;
  margin-top: 0.5rem;
}

.gr-default-control {
  width: 5%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.delete-gr {
  font-size: 2rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #bcc1c56f;
  margin-left: 0.2rem;
  cursor: pointer;
}

.filler {
  display: flex;
  height: 4rem;
}
