.login-flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--main);
  height: 92vh;
}

.scrum-svg {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 65%;
  /* margin: 8.0rem; */
  height: 80%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("./../fi.png");
  /* margin-bottom: 10.0rem; */
}

.scrum-svg img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

.login-screen {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: auto;
  margin-bottom: 8rem;
}

.scrum-svg > img {
  object-fit: cover;
  max-width: 80%;
}

.login-screen > div {
  /* padding: 2.0rem; */
  margin: 2rem;
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.control {
  margin-bottom: 2rem;
  width: 60%;
  align-self: center;
}

.login-header {
  font-family: "Poppins";
  font-size: 4rem;
  font-weight: bold;
  color: var(--white);
}

.control > input {
  height: 3rem;
  width: 100%;
  border-radius: 2.5rem;
  border-color: var(--white);
  /* text-align: center; */
}

::placeholder {
  font-family: "Poppins";
  font-size: 1.3rem;
  font-weight: bolder;
  color: #cccccc;
  letter-spacing: 0.1rem;
  font-style: normal;
  text-align: center;
}

input {
  height: 4rem;
  border-radius: 1.2rem;
  font-family: "Poppins";
  font-size: 1.5rem;
  border-style: hidden;
  /* font-weight: bolder; */
  text-align: center;
}

select {
  height: 4rem;
  border-radius: 1.2rem;
  font-family: "Poppins";
  font-size: 1.5rem;
  border-style: hidden;
  /* font-weight: bolder; */
  text-align: center;
  /* color: var(--dark); */
  outline-style: none;
}

textarea {
  border-radius: 1.2rem;
  font-family: "Poppins";
  font-size: 1.5rem;
  border-style: hidden;
  /* padding: 1.0rem .0rem; */
  /* font-weight: bolder; */
}

textarea::placeholder {
  /* padding-top: 2.5rem; */
  text-align: center;
  vertical-align: middle;
}

.submit {
  border-radius: 2rem;
  background-color: var(--accent);
  padding: 0.5rem 2rem 0.5rem 2rem;
  text-align: center;
  align-self: center;
  color: var(--white);
  font-weight: bold;
  font-size: 2rem;
  font-family: "Poppins";
  cursor: pointer;
  box-shadow: 0rem 0.5rem 0.3rem 0rem rgba(0, 0, 0, 0.2);
}

#Light {
  animation: animateLeft 5s infinite alternate;
  /*   transform-origin : center; */
  transform-box: fill-box;
}

@keyframes animateLeft {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(75deg);
  }
}

.version {
  color: white;
  position: fixed;
  right: 1%;
  bottom: 1%;
}
