.p-menu {
  /* margin: .2rem .0rem; */
}

.p-menu-sidebar {
  background-color: var(--main);
  box-shadow: 0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.466);
  z-index: 10;
}

.f-p-dtl-head {
  display: flex;
  justify-content: flex-start;
  width: 95%;
  margin-top: 1rem;
  height: 5%;
  align-items: center;
  font-size: 1.8rem;
  gap: 2rem;
}
