.analytics-preview {
  position: fixed;
  top: 5%;
  bottom: 5%;
  left: 5%;
  right: 5%;
  background-color: white;
  border: 0.3rem solid black;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 0 9999.9rem rgba(0, 0, 0, 0.8);
  z-index: 100;
}

.created-container {
  content: "";
  width: 100%;
  /* border-width: .1rem; */
  /* border-style: dashed; */
  margin: 1rem 0rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 0.5rem;
}

.created-row {
  display: flex;
  /* border: .2rem solid #0055c2; */
  /* width: 100%; */
  gap: 0.5rem;
}

.viz-preview-details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 97%;
  gap: 1rem;
  margin-left: 2rem;
  margin-top: 1rem;
  /* border: 1px solid red; */
}

.viz-preview-details > .viz-name {
  font-size: 2rem;
  /* margin-bottom: 1rem; */
}

.viz-preview-details > .viz-filter > select {
  font-size: 1.2rem;
  /* height: 100%; */
  height: 3rem;
  margin-left: 1rem;
}

.grow {
  flex-grow: 1;
}

.close-icon > i {
  font-size: 2rem;
  cursor: pointer;
}

.btn-controls {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
  gap: 2rem;
  margin-bottom: 2rem;
}
