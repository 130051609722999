.creation-cell-ta {
  height: 100%;
  margin: 0.2rem;
  background-color: #bcc1c56f;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 10rem;
  cursor: pointer;
  position: relative;
}

.created-row-ta {
  display: flex;
  flex-grow: 1;
}

.cell-control-ta {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.cell-control-ta > textarea {
  width: 98%;
  background-color: var(--white);
  font-weight: normal;
  height: 96%;
}
