.creation-cell {
  /* height: 100%; */
  /* border: .1rem solid red; */
  margin: 0.2rem;
  background-color: #bcc1c56f;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 10rem;
  cursor: pointer;
  position: relative;
  height: 5rem;
  /* animation: tinLeftIn 1s 1; */
}

@keyframes wobble {
  0% {
    transform: translateY(0%);
  }
  15% {
    transform: translateY(-2%);
  }
  30% {
    transform: translateY(2%);
  }
  45% {
    transform: translateY(-1.5%);
  }
  60% {
    transform: translateY(1%);
  }
  75% {
    transform: translateY(-0.5%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes tinLeftIn {
  0% {
    opacity: 0;
    transform: scale(1, 1) translateX(-900%);
  }
  50%,
  70%,
  90% {
    opacity: 1;
    transform: scale(1.1, 1.1) translateX(0);
  }
  60%,
  80%,
  100% {
    opacity: 1;
    transform: scale(1, 1) translateX(0);
  }
}

.grid-creation-cell {
  margin: 0.2rem;
  background-color: #bcc1c56f;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 10rem;
  cursor: pointer;
  position: relative;
  height: 10rem;
  flex-grow: 1;
}

.grid-creation-cell-wh {
  margin: 0.2rem;
  background-color: #bcc1c56f;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 10rem;
  cursor: pointer;
  position: relative;
  /* height: 5.0rem; */
  flex-grow: 1;
  padding: 0.5rem 0rem;
}

.item-drop {
  opacity: 0.5;
}

.cell-name {
  font-size: 1.6rem;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}

.cell-name-grid {
  font-size: 1.6rem;
  /* width: 30%; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}

.clicked-cell {
  background-color: rgba(255, 149, 111, 0.439);
}

.cell-img {
  background-repeat: no-repeat;
  background-size: contain;
  height: 4rem;
  width: 4rem;
}

.cell-control {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.cell-control > div {
  width: 100%;
  height: 100%;
}

.cell-control > input {
  width: 98%;
  font-weight: normal;
}

.r-r > input[type="radio"] {
  margin: auto 0.5rem;
  width: 100%;
}

.r-r {
  display: flex;
  width: 20%;
}

.r-label {
  display: flex;
  /* width: 90%; */
}
.cell-control > input:disabled {
  background-color: var(--white);
}

.cell-control > input[type="file"] {
  text-align-last: left;
  direction: ltr;
  display: inline-block;
  content: "Attach";
  border-radius: none;
  line-height: 4rem;
  background-color: var(--white);
}

input::file-selector-button {
  background-color: dodgerblue;
  color: white;
  height: 100%;
  float: left;
  font-family: "Poppins";
  cursor: pointer;
  border-radius: 1.2rem 0rem 0rem 1.2rem;
  outline: 0.3rem outset dodgerblue;
  outline-offset: -0.3rem;
  /* margin: auto .5rem; */
}

.cell-control > select {
  width: 98%;
  font-weight: normal;
}

.cell-control > textarea {
  width: 98%;
  background-color: var(--white);
  font-weight: normal;
  /* height: 10%; */
}

.cell-control > textarea::placeholder {
  text-align: center;
  line-height: 0rem;
  font-weight: normal;
}

.r-group {
  display: flex;
  font-size: 1.4rem;
  justify-content: center;
  align-items: center;
}

.f-btn {
  width: 90%;
  height: 90%;
  font-size: 1.6rem;
  font-family: Poppins;
  border-radius: 1rem;
  cursor: pointer;
}

.btn-cell-control {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.transparent-btn {
  pointer-events: none;
}

.hidden-header {
  color: #ffffff00;
  height: 0rem;
}

.section-heading-ctrl {
  /* border-top: .2rem double red;
  border-bottom: .2rem double red; */
  background-color: var(--main);
  color: var(--white);
  /* border: none; */
  padding: 0.5rem 0rem;
  font-size: 1.6rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.sample-div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
