.f-dtl-container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  background-color: var(--white);
  width: 80%;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
}

.f-dtl-head {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin-top: 1rem;
  height: 5%;
  align-items: center;
  font-size: 1.8rem;
}

.f-sub-dtl-head {
  display: flex;
  justify-content: space-between;
  margin-left: 1rem;
  /* width: 10%; */
  /* margin-top: 1.0rem; */
  /* height: 5%; */
  align-items: center;
  font-size: 1.6rem;
}

.i-btn {
  display: flex;
  align-items: center;
  background-color: var(--green);
  padding: 0.5rem 2rem;
  color: var(--white);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.466);
  font-size: 1.6rem;
  cursor: pointer;
}

.f-i-btn {
  display: flex;
  align-items: center;
  background-color: var(--green);
  padding: 0.5rem 2rem;
  color: var(--white);
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.466);
  font-size: 1.6rem;
  font-family: "Poppins";
  cursor: pointer;
  border-radius: 0rem;
  text-transform: "none" !important;
  border: none;
}

.f-dtl {
  display: flex;
  width: 95%;
}

.f-table {
  display: flex;
  width: 100%;
  /* height: 90%; */
  justify-content: center;
  margin-top: 1rem;
}

.f-dept-table {
  display: flex;
  width: 100%;
  height: 90%;
  justify-content: center;
  margin-top: 1rem;
}

.MuiPaper-elevation {
  box-shadow: 0rem 0rem 0rem white;
  width: 98%;
  box-shadow: 0rem 0rem 0rem white;
  shadows: none;
}

.MuiPaper-root {
  box-shadow: none !important;
}

.MuiMenu-paper {
  width: auto;
}

table {
  margin-bottom: 1rem;
}

.c-actions {
  text-align: center;
}

.a-icon > path {
  stroke: var(--accent);
  fill: var(--accent);
  stroke-width: 0.1;
}

.btns {
  display: flex;
  gap: 1rem;
}
