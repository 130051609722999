.viz-option {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 1rem;
  color: var(--black);
  width: 20%;
}

.viz-option > i {
  text-shadow: 0.2rem 0.2rem #bcc1c56f;
}

.viz-option-dragging {
  background-color: rgba(0, 0, 0, 0.211);
  opacity: 0.5;
}

.viz-img {
  background-repeat: no-repeat;
  background-size: contain;
  height: 3rem;
  width: 3rem;
}

.text-png {
  background-image: url("../../public/text.png");
}

.select-png {
  background-image: url("../../public/select.png");
}

.textarea-png {
  background-image: url("../../public/textarea.png");
}

.radio-png {
  background-image: url("../../public/radio.png");
}

.checkbox-png {
  background-image: url("../../public/checkbox.png");
}

.barcode-png {
  background-image: url("../../public/barcode.png");
}

.datetime-png {
  background-image: url("../../public/datetime.png");
}

.button-png {
  background-image: url("../../public/button.png");
}

.user-png {
  background-image: url("../../public/user.png");
}

.all-users-png {
  background-image: url("../../public/all-users.png");
}

.multiselect-png {
  background-image: url("../../public/multiselect.png");
}

.attachment-png {
  background-image: url("../../public/attachment.png");
}

.section-heading-png {
  background-image: url("../../public/section-heading.png");
}

.formula-png {
  background-image: url("../../public/formula.png");
}

.numbers-png {
  background-image: url("../../public/numbers.png");
}

.control-name {
  font-size: 1.4rem;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: .1rem dotted black; If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  /* padding: .5rem 1.0rem; */
  position: absolute;
  z-index: 1;
  font-size: 1.2rem;
  /* top: -100%; */
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltips:hover .tooltiptext {
  visibility: visible;
}
