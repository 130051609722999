.dashboard-container {
  display: flex;
  /* width: 100%; */
  height: 92vh;
  /* background-color: var(--darktrans); */
  background-color: var(--white);
  overflow-y: auto;
  align-items: center;
  flex-wrap: wrap;
  gap: 5rem;
  /* padding-left: 3.0rem; */
  align-content: flex-start;
  justify-content: center;
  /* margin-bottom: 3.0rem; */
}

.dashboard-container > * {
  margin-top: 3rem;
  /* margin-right: 3.0rem; */
}

.edgePath > .path {
  stroke-width: 1;
}
